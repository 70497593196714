var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("q-form", { ref: "editForm" }, [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm",
                attrs: { title: "LBLBASEINFO" },
              },
              [
                _c(
                  "template",
                  { slot: "card-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-appr-btn", {
                          ref: "appr-btn",
                          attrs: {
                            name: "appr-btn",
                            editable: _vm.editable,
                            approvalInfo: _vm.approvalInfo,
                          },
                          on: {
                            beforeApprAction: _vm.approvalSave,
                            callbackApprAction: _vm.approvalSaveCallback,
                            requestAfterAction: _vm.getDetail,
                          },
                        }),
                        _c("c-btn", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.editable && _vm.isOld && !_vm.disabled,
                              expression: "editable&&isOld&&!disabled",
                            },
                          ],
                          attrs: { label: "LBLREMOVE", icon: "delete" },
                          on: { btnClicked: _vm.deleteTarget },
                        }),
                        _c("c-btn", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.editable && !_vm.disabled,
                              expression: "editable&&!disabled",
                            },
                          ],
                          attrs: {
                            isSubmit: _vm.isSave,
                            url: _vm.saveUrl,
                            param: _vm.target,
                            mappingType: _vm.mappingType,
                            label: "LBLSAVE",
                            icon: "save",
                          },
                          on: {
                            beforeAction: _vm.saveTarget,
                            btnCallback: _vm.saveTargetCallback,
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-plant", {
                        attrs: {
                          required: true,
                          editable: _vm.editable,
                          disabled: _vm.disabled,
                          name: "plantCd",
                        },
                        model: {
                          value: _vm.target.plantCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.target, "plantCd", $$v)
                          },
                          expression: "target.plantCd",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                    },
                    [
                      _c("c-text", {
                        attrs: {
                          required: true,
                          editable: _vm.editable,
                          disabled: _vm.disabled,
                          label: "제목",
                          name: "targetTitle",
                        },
                        model: {
                          value: _vm.target.targetTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.target, "targetTitle", $$v)
                          },
                          expression: "target.targetTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-datepicker", {
                        attrs: {
                          required: true,
                          editable: _vm.editable,
                          disabled: _vm.isOld,
                          type: "month",
                          default: "today",
                          label: "작성월",
                          name: "targetMonth",
                        },
                        on: { datachange: _vm.datachange },
                        model: {
                          value: _vm.target.targetMonth,
                          callback: function ($$v) {
                            _vm.$set(_vm.target, "targetMonth", $$v)
                          },
                          expression: "target.targetMonth",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2",
                    },
                    [
                      _c("c-dept", {
                        attrs: {
                          required: true,
                          editable: _vm.editable,
                          disabled: _vm.disabled,
                          type: "edit",
                          label: "LBLWRITEDEPT",
                          name: "writeDeptCd",
                        },
                        model: {
                          value: _vm.target.writeDeptCd,
                          callback: function ($$v) {
                            _vm.$set(_vm.target, "writeDeptCd", $$v)
                          },
                          expression: "target.writeDeptCd",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c("c-tab", {
              attrs: {
                type: "tabcard",
                tabItems: _vm.tabItems,
                inlineLabel: true,
                height: _vm.tabHeight,
                align: "left",
              },
              on: {
                "update:tabItems": function ($event) {
                  _vm.tabItems = $event
                },
                "update:tab-items": function ($event) {
                  _vm.tabItems = $event
                },
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (tab) {
                    return [
                      _c(tab.component, {
                        tag: "component",
                        attrs: {
                          popupParam: _vm.popupParam,
                          target: _vm.target,
                          contentHeight: _vm.contentHeight,
                          attachInfo: _vm.attachInfo,
                        },
                        on: {
                          "update:popupParam": function ($event) {
                            _vm.popupParam = $event
                          },
                          "update:popup-param": function ($event) {
                            _vm.popupParam = $event
                          },
                          "update:target": function ($event) {
                            _vm.target = $event
                          },
                          getDetail: _vm.getDetail,
                        },
                      }),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            }),
          ],
          1
        ),
        _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }